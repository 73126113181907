import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Schedule, UploadSharp, CachedOutlined } from "@mui/icons-material"
import {
  getBankList,
  getPaymentMethods,
  getProcessSchedule,
  getTransactionList,
  initiateProcessSchedule,
  PaginationType,
  resetFilters,
  selectSortModel,
  selectTransactions,
  setFilters,
  setFiltersFromURL,
  setPagination,
} from "../../slices/transactionsSlice"
import PageHeader from "../../components/PageHeader/PageHeader"
import {
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { TransactionTable } from "./TransactionTable"
import UploadPopup from "./UploadPopup"
import TransactionsFilters from "./TransactionsFilter"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
import { allowedOnlyBankAccountsRoles, UserRole } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { GridSortItem, GridSortModel } from "@mui/x-data-grid"
import { StyledButton, PrimaryButton } from "./StyledButton"
import { ROOT } from "../../constants/path"
import {
  ReconcilationMessage,
  ReconcilationMessageText,
  TransactionsContainer,
} from "./styles"

export const Transactions = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const {
    transactionList,
    pageInfo,
    isLoading,
    filters,
    bankList,
    pagination,
    isUploading,
    processScheduleData,
    isProcessScheduleLoading,
  } = useAppSelector(selectTransactions)
  const { role } = useAppSelector(selectLogin)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  useEffect(() => {
    dispatch(getBankList())
    dispatch(getPaymentMethods())
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)

    if (Object.keys(params).length !== 0) {
      // URL has filter parameters
      if (!Array.isArray(params.page) && !Array.isArray(params.pageSize)) {
        const page = parseInt(params.page || "0")
        const pageSize = parseInt(params.pageSize || "10")
        dispatch(setPagination({ page, pageSize }))
      }
      const updatedFilters = { ...filters, ...params }
      dispatch(setFiltersFromURL(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set filters to URL for sharing purpose
    const params = queryString.stringify({ ...filters, ...pagination })
    navigate({ search: params })
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, pagination])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }
  }

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true)
  }

  const handlesetUploadDialogClose = () => {
    setUploadDialogOpen(false)
  }

  const setPaginationModel = (page: PaginationType) => {
    dispatch(setPagination(page))
  }

  const sortModel = useAppSelector(selectSortModel)

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (!newSortModel.length) {
      dispatch(setFilters({ ...filters, sortBy: "none" }))
    } else {
      dispatch(
        setFilters({
          ...filters,
          sortBy: newSortModel[0].field,
          orderBy: newSortModel[0].sort,
        }),
      )
    }
  }

  const handleProcessSchedule = () => {
    dispatch(initiateProcessSchedule())
  }

  return (
    <>
      <PageHeader
        title="Transactions"
        breadcrums={[{ title: "Home", path: ROOT }, { title: "Transactions" }]}
        actions={
          <Stack spacing={1} direction="row">
            <PrimaryButton
              variant="contained"
              startIcon={<UploadSharp fontSize="small" />}
              onClick={handleUploadDialogOpen}
              disabled={
                isUploading ||
                role === UserRole.ACCOUNT ||
                role === UserRole.ALTS_SALES
              }
            >
              {isUploading ? (
                <CircularProgress size={26} color="primary" />
              ) : (
                "Upload"
              )}
            </PrimaryButton>
            {allowedOnlyBankAccountsRoles.includes(role) ? (
              <Tooltip
                title={
                  processScheduleData.status
                    ? processScheduleData?.message
                    : "Click to process schedule"
                }
              >
                {processScheduleData.status ? (
                  <ReconcilationMessage>
                    Next reconcilation scheduled at
                    <ReconcilationMessageText>
                      {processScheduleData.nextRun}
                    </ReconcilationMessageText>
                  </ReconcilationMessage>
                ) : (
                  <StyledButton
                    variant="outlined"
                    startIcon={<Schedule fontSize="small" />}
                    color="inherit"
                    disabled={isProcessScheduleLoading}
                    onClick={handleProcessSchedule}
                  >
                    {isProcessScheduleLoading ? (
                      <CircularProgress size={26} color="primary" />
                    ) : (
                      "Reschedule"
                    )}
                  </StyledButton>
                )}
              </Tooltip>
            ) : null}
            <Divider orientation="vertical" flexItem />
            <StyledButton
              variant="outlined"
              startIcon={<CachedOutlined fontSize="small" />}
              color="inherit"
              onClick={handleRefresh}
            >
              Refresh
            </StyledButton>
          </Stack>
        }
      />
      <TransactionsContainer>
        <TransactionsFilters
          isSmallScreen={isSmallScreen}
          resetFilterHandler={resetFilterHandler}
        />
        <TransactionTable
          transactions={transactionList}
          isLoading={isLoading}
          pageInfo={pageInfo}
          paginationModel={pagination}
          setPaginationModel={setPaginationModel}
          sortModel={sortModel as GridSortItem[]}
          onSortModelChange={handleSortModelChange}
        />
        {uploadDialogOpen && (
          <UploadPopup
            open={uploadDialogOpen}
            onClose={handlesetUploadDialogClose}
            bankList={bankList}
          />
        )}
      </TransactionsContainer>
    </>
  )
}
