import {
  Box,
  Checkbox,
  InputAdornment,
  TextField,
  ToggleButton,
  Tooltip,
} from "@mui/material"
import { InvoiceIcon } from "./InvoiceIcon"
import {
  InvoiceItemHeader,
  InvoiceItemContainer,
  StyledSwitch,
  StyledToggleButtonGroup,
  InvoiceItemHeaderContent,
  InvoiceItemHeaderText,
  InvoiceItemTaxDetails,
  TdsContainer,
  TdsAmount,
  TdsLabel,
  ErrorText,
  InputHelperText,
} from "./styles"
import { formatPriceWithRupeeSymbol } from "../../utils/price-formatter"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useMemo } from "react"
import { Invoice } from "../../models/invoice.model"
import {
  ReconciliationFormItem,
  ReconciliationFormValues,
} from "../../models/reconciliation.model"
import { FormikProps } from "formik"
import { titleCase } from "../../utils/transactionHelpers"

export interface InvoicItemProps {
  txnInvoiceSoruce: string
  isSelected: boolean
  reconciliationItem: ReconciliationFormItem | null
  formik: FormikProps<ReconciliationFormValues>
  invoice: Invoice
  handleInvoiceToggle: (invoice: Invoice, checked: boolean) => void
  handleTaxPercentToggle: (invoice: Invoice, taxPercent: 10 | 2 | null) => void
  handleAmountChange: (invoiceId: string, amount: number | "") => void
  handleTaxEnabledToggle: (invoice: Invoice, isEnabled: boolean) => void
  itemIndex: number
  isTransactionAmountConsumed: boolean
  balanceAmountCalculationText: Record<string, string>
}

export const InvoiceItem: React.FC<InvoicItemProps> = ({
  txnInvoiceSoruce: txnInvoiceSource,
  isSelected,
  reconciliationItem,
  formik,
  invoice,
  handleInvoiceToggle,
  itemIndex,
  handleTaxPercentToggle,
  handleAmountChange,
  handleTaxEnabledToggle,
  isTransactionAmountConsumed,
  balanceAmountCalculationText,
}) => {
  const isSameSource = useMemo(
    () => invoice.source === txnInvoiceSource,
    [invoice, txnInvoiceSource],
  )

  const invoiceSourceErrorMessage = useMemo(
    () =>
      `Invoice (${titleCase(invoice.source)}) and transaction (${titleCase(
        txnInvoiceSource,
      )}) sources do not match`,
    [invoice, txnInvoiceSource],
  )

  const isBalanceAmountNegative = useMemo(() => {
    return formik.values.balanceAmount < 0
  }, [formik.values.balanceAmount])

  const handleInvoiceClick = () => {
    if (!isSameSource) {
      return
    }
    if (isSelected) {
      handleInvoiceToggle(invoice, false)
    } else if (!isTransactionAmountConsumed) {
      handleInvoiceToggle(invoice, true)
    }
  }

  return (
    <InvoiceItemContainer selected={isSelected}>
      <InvoiceItemHeader selected={isSelected} onClick={handleInvoiceClick}>
        <Box>
          <InvoiceItemHeaderContent>
            <InvoiceIcon />
            <InvoiceItemHeaderText>
              {invoice.invoiceNumber} | Amount :{" "}
              {formatPriceWithRupeeSymbol(invoice.total)}
              {invoice.total !== invoice.balance && (
                <>
                  {" "}
                  | Due Amount : {formatPriceWithRupeeSymbol(invoice.balance)}
                </>
              )}
            </InvoiceItemHeaderText>
          </InvoiceItemHeaderContent>
          {isSameSource ? null : (
            <ErrorText>{invoiceSourceErrorMessage}</ErrorText>
          )}
        </Box>
        {isSameSource ? (
          <Checkbox
            disabled={isTransactionAmountConsumed && !isSelected}
            checked={isSelected}
            onChange={handleInvoiceClick}
          />
        ) : (
          <Tooltip title={invoiceSourceErrorMessage}>
            <InfoOutlinedIcon color="error" />
          </Tooltip>
        )}
      </InvoiceItemHeader>
      {isSelected && reconciliationItem && (
        <InvoiceItemTaxDetails>
          {invoice.tdsAlreadyDeducted !== 0 && (
            <TdsAmount component="span">
              TDS previously deducted :{" "}
              {formatPriceWithRupeeSymbol(invoice.tdsAlreadyDeducted)}
            </TdsAmount>
          )}
          {invoice.canTdsDeducted && (
            <TdsContainer>
              <TdsLabel component="span">TDS Deducted?</TdsLabel>
              <StyledSwitch
                component="span"
                checked={reconciliationItem.isTdsDeducted}
                name={`reconciliationItems.${itemIndex}.isTdsDeducted`}
                onChange={(event, val) => {
                  handleTaxEnabledToggle(invoice, val)
                }}
              ></StyledSwitch>
              {reconciliationItem.isTdsDeducted && (
                <>
                  <TdsLabel component="span">TDS %</TdsLabel>
                  <StyledToggleButtonGroup
                    color="primary"
                    value={reconciliationItem.tdsPercentage}
                    exclusive
                    onChange={(event, val) => {
                      handleTaxPercentToggle(invoice, val)
                    }}
                    aria-label="Platform"
                    size="small"
                  >
                    <ToggleButton
                      value={10}
                      size="small"
                      disabled={!invoice.canTenPercentTdsDeducted}
                    >
                      10%
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      size="small"
                      disabled={!invoice.canTwoPercentTdsDeducted}
                    >
                      2%
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <TdsAmount component="span">
                    Deducted TDS :{" "}
                    {formatPriceWithRupeeSymbol(
                      reconciliationItem.taxAmountWithheld,
                    )}
                  </TdsAmount>
                </>
              )}
            </TdsContainer>
          )}
          <TextField
            fullWidth
            label="Amount"
            type="number"
            name={`reconciliationItems.${itemIndex}.amount`}
            size="small"
            value={reconciliationItem.amount}
            onChange={(event) => {
              if (event.target.value === "") {
                handleAmountChange(invoice.invoiceId, "")
              } else {
                handleAmountChange(
                  invoice.invoiceId,
                  Number.parseFloat(event.target.value),
                )
              }
            }}
            onBlur={formik.handleBlur}
            error={
              (formik as any).errors.reconciliationItems?.[itemIndex]?.amount &&
              Boolean(
                (formik as any).errors.reconciliationItems?.[itemIndex]?.amount,
              )
            }
            helperText={
              (formik as any).errors.reconciliationItems?.[itemIndex]?.amount &&
              (formik as any).errors.reconciliationItems?.[itemIndex]?.amount
            }
            InputProps={{
              endAdornment:
                isBalanceAmountNegative &&
                Boolean(balanceAmountCalculationText[invoice.invoiceId]) ? (
                  <InputAdornment position="start">
                    <InputHelperText>
                      {balanceAmountCalculationText[invoice.invoiceId]}
                    </InputHelperText>
                  </InputAdornment>
                ) : null,
            }}
          />
        </InvoiceItemTaxDetails>
      )}
    </InvoiceItemContainer>
  )
}
