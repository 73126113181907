import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { FormikProps } from "formik"
import { Transaction } from "../../models/transactions.model"
import {
  ReconciliationFormItem,
  ReconciliationFormValues,
} from "../../models/reconciliation.model"
import { RootState } from "../../app/store"
import { PendingInvoicesTitle } from "./styles"
import { InvoiceItem } from "./InvoiceItem"
import { Invoice } from "../../models/invoice.model"
import { Alert } from "@mui/material"

interface PendingInvoicesListProps {
  transactionData: Transaction
  formikProps: FormikProps<ReconciliationFormValues>
}

export const PendingInvoicesList: React.FC<PendingInvoicesListProps> = ({
  transactionData,
  formikProps,
}) => {
  const [balanceAmountCalculationText, setBalanceAmountCalculationText] =
    useState<Record<string, string>>({})

  const { selectedCustomer, invoices, invoicesFetchError } = useSelector(
    (state: RootState) => state.reconciliation,
  )

  const isTransactionAmountConsumed = useMemo(() => {
    return formikProps.values.balanceAmount <= 0
  }, [formikProps.values.balanceAmount])

  const updateBalanceAmount = (
    reconciliationItems: ReconciliationFormItem[],
  ) => {
    const balance =
      transactionData.amount -
      reconciliationItems.reduce((acc, current) => {
        if (current.amount) {
          acc = acc + current.amount
        }
        return acc
      }, 0)
    formikProps.setFieldValue("balanceAmount", balance)
    updateBalanceCalculationText(reconciliationItems)
  }

  const updateBalanceCalculationText = (
    reconciliationItems: ReconciliationFormItem[],
  ) => {
    let calculationText = {}
    reconciliationItems.forEach((item) => {
      const balance =
        transactionData.amount -
        reconciliationItems.reduce((acc, current) => {
          if (current.amount && current.invoiceId !== item.invoiceId) {
            acc = acc + current.amount
          }
          return acc
        }, 0)
      const text = `${transactionData.amount} ${reconciliationItems.reduce(
        (acc, current) => {
          if (current.amount && current.invoiceId !== item.invoiceId) {
            acc = acc + ` - ${current.amount}`
          }
          return acc
        },
        ``,
      )} = ${balance}`
      if (balance > 0) {
        if (balance !== transactionData.amount) {
          calculationText = {
            ...calculationText,
            [item.invoiceId]: text,
          }
        } else {
          calculationText = {
            ...calculationText,
            [item.invoiceId]: balance.toString(),
          }
        }
      }
    })
    setBalanceAmountCalculationText(calculationText)
  }

  const getTaxAmountWithheld = (
    invoice: Invoice,
    tdsPercentage: 10 | 2 | 0,
  ): number => {
    if (tdsPercentage === 10) {
      return invoice.tds10Percent - invoice.tdsAlreadyDeducted
    } else if (tdsPercentage === 2) {
      return invoice.tds2Percent - invoice.tdsAlreadyDeducted
    }
    return 0
  }

  const handleInvoiceToggle = (invoice: Invoice, checked: boolean) => {
    const currentItems = [...formikProps.values.reconciliationItems]
    if (checked) {
      const newItem: ReconciliationFormItem = {
        invoiceId: invoice.invoiceId,
        invoiceNumber: invoice.invoiceNumber,
        balanceAmount: invoice.balance,
        amount:
          invoice.balance -
          getTaxAmountWithheld(invoice, 10) -
          invoice.tdsAlreadyDeducted,
        isTdsDeducted: invoice.canTdsDeducted,
        taxAmountWithheld: getTaxAmountWithheld(invoice, 10),
        tdsPercentage: invoice.canTenPercentTdsDeducted ? 10 : 2,
        tdsAlreadyDeducted: invoice.tdsAlreadyDeducted,
      }
      currentItems.push(newItem)
    } else {
      const index = currentItems.findIndex(
        (item) => item.invoiceId === invoice.invoiceId,
      )
      if (index !== -1) {
        currentItems.splice(index, 1)
      }
    }
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleTaxEnabledToggle = (invoice: Invoice, isEnabled: boolean) => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoice.invoiceId) {
        item.isTdsDeducted = isEnabled
        item.taxAmountWithheld = getTaxAmountWithheld(
          invoice,
          isEnabled ? item.tdsPercentage : 0,
        )
        item.amount =
          invoice.balance -
          getTaxAmountWithheld(invoice, isEnabled ? item.tdsPercentage : 0) -
          invoice.tdsAlreadyDeducted
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleTaxPercentToggle = (
    invoice: Invoice,
    taxPercent: 10 | 2 | null,
  ) => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoice.invoiceId && taxPercent) {
        item.tdsPercentage = taxPercent
        item.taxAmountWithheld = getTaxAmountWithheld(invoice, taxPercent)
        item.amount =
          invoice.balance -
          getTaxAmountWithheld(invoice, taxPercent) -
          invoice.tdsAlreadyDeducted
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleAmountChange = (invoiceId: string, amount: number | "") => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoiceId) {
        item.amount = amount
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const isInvoiceSelected = (invoiceId: string): boolean => {
    return formikProps.values.reconciliationItems.some(
      (item) => item.invoiceId === invoiceId,
    )
  }

  const getReconciliationItemIndex = (invoiceId: string): number => {
    return formikProps.values.reconciliationItems.findIndex(
      (item) => item.invoiceId === invoiceId,
    )
  }

  if (!selectedCustomer) {
    return null
  }

  return (
    <>
      <PendingInvoicesTitle>Pending Invoices</PendingInvoicesTitle>
      {Boolean(invoicesFetchError) && (
        <Alert severity="warning">{invoicesFetchError}</Alert>
      )}
      {invoices.map((invoice) => {
        const isSelected = isInvoiceSelected(invoice.invoiceId)
        const itemIndex = getReconciliationItemIndex(invoice.invoiceId)
        const reconciliationItem = isSelected
          ? formikProps.values.reconciliationItems[itemIndex]
          : null
        return (
          <InvoiceItem
            key={invoice.invoiceId}
            txnInvoiceSoruce={transactionData.invoiceSource}
            formik={formikProps}
            isSelected={isSelected}
            invoice={invoice}
            reconciliationItem={reconciliationItem}
            handleInvoiceToggle={handleInvoiceToggle}
            itemIndex={itemIndex}
            handleTaxPercentToggle={handleTaxPercentToggle}
            handleAmountChange={handleAmountChange}
            handleTaxEnabledToggle={handleTaxEnabledToggle}
            isTransactionAmountConsumed={isTransactionAmountConsumed}
            balanceAmountCalculationText={balanceAmountCalculationText}
          />
        )
      })}
    </>
  )
}
